import { RadioGroup } from "@headlessui/react";
import CheckIcon from "./CheckedIcon";

export default function RadioGroupMenu({ data, selected, setMethod }) {
  const handleSetSelected = (selectedUser) => {
    if (selected?.$id === selectedUser.$id) {
      setMethod({});
      return;
    }
    setMethod(selectedUser);
  };
  const handleUnselect = () => {
    setMethod({});
  };

  return (
    <div className="w-full px-4 py-4">
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={handleSetSelected}>
          <RadioGroup.Label className="sr-only">list of users</RadioGroup.Label>
          <div style={{ fontFamily: "poppins" }} className="space-y-2">
            {data.map((item) => (
              <RadioGroup.Option
                key={item.userName}
                value={item}
                onClick={(e) => {
                  if (selected.$id === item.$id) {
                    handleUnselect();
                  }
                }}
                className={({ active, checked }) =>
                  `${
                    active
                      ? "ring-2 ring-white text-titles-800 "
                      : "hover:bg-amber-200"
                  }
                  ${checked ? "bg-gray-200 bg-opacity-85 text-titles-800" : ""}
                    relative rounded-md p-2 hover:bg-gray-200 cursor-pointer`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? "text-titles-800" : "text-titles-800"
                            }`}
                          >
                            {/* {item.pereference.color} */}
                            {item.userName}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? "text-sky-100" : "text-titles-800"
                            }`}
                          ></RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-5 w-5" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}
