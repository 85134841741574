import { useState, useEffect } from "react";
import api from "../api/api";
import { FetchState } from "../hooks";
import Avatar from "react-avatar";
import { useLocation, Link } from "react-router-dom";
import { useStore } from "../hooks/useStore";
// import { BiBell } from "react-icons/bi";
import { FiChevronDown, FiSettings, FiLogOut, FiInfo } from "react-icons/fi";
import { RxSlash } from "react-icons/rx";
import { Server } from "../utils/config";

const TheHeader = ({ user, userTeam, dispatch, startTour }) => {
  const location = useLocation();
  const setIsModalOpen = useStore((state) => state.setIsModalOpen);
  const setIsAvatarModalOpen = useStore((state) => state.setIsAvatarModalOpen);
  const teamOwnerId = useStore((state) => state.teamOwnerId);
  const teamLogoId = useStore((state) => state.teamLogoId);
  const [showMenu, setShowMenu] = useState(false);
  const [active, setActive] = useState("dashboard");
  const isUserTeamOwner = teamOwnerId.includes(user?.$id) || false;

  const capitalizeName = (name) => {
    var separateWord = name.toLowerCase().split(" ");
    for (var i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  };

  useEffect(() => {
    const currentLocation = location.pathname.slice(1);
    setActive(currentLocation);

    return () => {};
  }, [location]);

  const goTo = (path) => {
    setActive(path);
  };

  const handleLogout = async (e) => {
    dispatch({ type: FetchState.FETCH_INIT });
    try {
      await api.deleteCurrentSession();
      dispatch({ type: FetchState.FETCH_SUCCESS, payload: {} });
    } catch (e) {
      dispatch({ type: FetchState.FETCH_FAILURE });
    }
  };

  const handleTour = () => {
    startTour(true);
  };

  const handleUserAvatarClick = () => {
    setIsAvatarModalOpen();
  };

  return (
    <div className="border-b-2">
      <nav className="bg-white-100">
        <div className="mx-auto px-4 sm:px-6 lg:px-6">
          <div className="flex items-center justify-between h-14">
            <div className="flex items-center">
              {/* {teamLogoId && ( */}
              <div className="flex flex-shrink-0 mr-2 ">
                <Link to="/" className="block" aria-label="Cruip">
                  <img
                    width="45px"
                    // height="45px"
                    src={
                      "https://res.cloudinary.com/nadlanitor-cloud/image/upload/v1689025787/weeklyz.pro/weeklyz_logo_3_colmhg.svg"
                    }
                    alt=""
                  />
                </Link>
                {/* <Link to="/" className="block" aria-label="Cruip">
                  <img
                    width="120px"
                    src={`${Server.endpoint}/storage/buckets/${Server.avatarsBucket}/files/${teamLogoId}/view?project=${Server.project}`}
                    alt=""
                  />
                </Link> */}
              </div>
              {userTeam?.name && (
                <div className="flex flex-shrink-0 mr-4 ">
                  <RxSlash color={"#E4E7EB"} style={{ marginTop: "0.25rem" }} />{" "}
                  <span className="ml-2">{userTeam.name}</span>
                  <RxSlash
                    color={"#E4E7EB"}
                    style={{ marginTop: "0.25rem", marginLeft: "1rem" }}
                  />
                </div>
              )}

              <div
                style={{
                  fontFamily: "poppins",
                  fontSize: "0.875rem",
                }}
                className="hidden md:block"
              >
                <div className="flex items-baseline space-x-6">
                  <Link
                    style={{ padding: "0.4rem" }}
                    to="/dashboard"
                    className={`${
                      active === "dashboard"
                        ? "text-brunch"
                        : " hover:text-gray-500"
                    }  cursor-pointer font-medium `}
                    aria-current="page"
                    onClick={() => goTo("dashboard")}
                  >
                    Dashboard
                  </Link>
                  <Link
                    id={"team"}
                    style={{ padding: "0.4rem" }}
                    to="/team"
                    className={`${
                      active === "team" ? "text-brunch" : " hover:text-gray-500"
                    }  cursor-pointer font-medium `}
                    aria-current="page"
                    onClick={() => goTo("team")}
                  >
                    Team
                  </Link>
                  {isUserTeamOwner && (
                    <div
                      id={"teamSettings"}
                      style={{ padding: "0.4rem" }}
                      className={`${
                        active === "settings"
                          ? "text-brunch"
                          : " hover:text-gray-500"
                      }  cursor-pointer font-medium `}
                      aria-current="page"
                      onClick={() => setIsModalOpen()}
                    >
                      Settings
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                {/* <button
                  type="button"
                  className=" p-1 rounded-full text-gray-400 hover:text-brunch focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BiBell size={20} />
                </button> */}
                {/* <!-- Profile dropdown --> */}
                <div className="ml-3 relative">
                  {/* <!--
                Dropdown menu, show/hide based on menu state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              --> */}
                  {showMenu && (
                    <div
                      className=" relative top-24 right-0 left-20 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 font-normal"
                      role="menu"
                      aria-orientation="vertical"
                      // aria-labelledby="user-menu-button"
                      tabIndex="-1"
                      style={{ fontFamily: "poppins" }}
                    >
                      {isUserTeamOwner && (
                        <div
                          onClick={() => {
                            setIsModalOpen();
                            setShowMenu(!showMenu);
                          }}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                          role="menuitem"
                          tabIndex="-1"
                          id="user-menu-item-1"
                        >
                          <FiSettings style={{ marginRight: "0.8rem" }} />{" "}
                          Settings
                        </div>
                      )}
                      {active === "dashboard" && (
                        <div
                          onClick={handleTour}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                          role="menuitem"
                          tabIndex="-1"
                          id="user-menu-item-2"
                        >
                          <FiInfo style={{ marginRight: "0.8rem" }} /> Take the
                          tour
                        </div>
                      )}
                      <div className="flex-grow border-t border-gray-200"></div>

                      <a
                        href="/"
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                        role="menuitem"
                        tabIndex="-1"
                        id="user-menu-item-2"
                      >
                        <FiLogOut style={{ marginRight: "0.8rem" }} />
                        Log out
                      </a>
                      <div className="flex-grow border-t border-gray-200"></div>
                      <div className="text-xs text-center py-2 px-2">
                        Weeklyz | Version 0.2.0
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="hover:opacity-75"
                  style={{ borderRadius: "20%", overflow: "hidden" }}
                >
                  <button
                    onClick={handleUserAvatarClick}
                    type="button"
                    className="max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white "
                  >
                    <span className="sr-only">Open user menu</span>
                    <Avatar
                      color={user?.prefs?.userPrefs?.userColor}
                      size="30"
                      fgColor="#674c10"
                      style={{
                        fontWeight: "bold",
                        fontFamily: "poppins",
                      }}
                      src={`${Server.endpoint}/storage/buckets/${Server.avatarsBucket}/files/${user?.prefs?.userPrefs?.avatarId}/view?project=${Server.project}`}
                      name={user.name}
                    />
                    {/* <img
                        className="h-8 w-8 rounded-full"
                        // src={user.avatar || ""}
                        alt=""
                      /> */}
                  </button>
                </div>
                <div className="ml-3 relative">
                  <div
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                    style={{ fontFamily: "poppins" }}
                    className="flex px-2 py-2 text-sm rounded-md font-semibold text-titles-800 hover:bg-gray-100 items-center"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <div className="mb-1 mr-2">{capitalizeName(user.name)}</div>
                    <FiChevronDown size={18} />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="-mr-2 flex md:hidden"
              onClick={() => setShowMenu(!showMenu)}
            >
              {/* <!-- Mobile menu button --> */}
              <button
                type="button"
                className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {/* <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            --> */}
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                {/* <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            --> */}
                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu, show/hide based on menu state. --> */}
        <div className="md:hidden" id="mobile-menu">
          <div
            className={`px-2 pt-2 pb-3 space-y-1 sm:px-3 ${
              showMenu ? "block" : "hidden"
            }`}
          >
            <div className="mt-3 px-2 space-y-1">
              <a
                href="/"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >
                Sign out
              </a>
            </div>
          </div>
          <div
            className={`px-2 pt-2 pb-3 space-y-1 sm:px-3 ${
              showMenu ? "block" : "hidden"
            }`}
          >
            {/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
            <a
              href="/"
              className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
              aria-current="page"
            >
              Dashboard
            </a>

            <Link
              onClick={() => {
                setShowMenu(!showMenu);
              }}
              to="/team"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              role="menuitem"
              tabIndex="-1"
              id="user-menu-item-1"
            >
              Team
            </Link>
            {isUserTeamOwner && (
              <div
                onClick={() => {
                  setIsModalOpen();
                  setShowMenu(!showMenu);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
                tabIndex="-1"
                id="user-menu-item-1"
              >
                Settings
              </div>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TheHeader;
