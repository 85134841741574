import React from "react";
import CheckIcon from "../../components/shared/CheckedIcon";
import categoriesArray from "../../constants/settingsCategories";
import { useStore } from "../../hooks/useStore";

export default function DaysTab() {
  const items = categoriesArray?.Days || [];
  const allowedDays = useStore((state) => state.allowedDays);
  const addAllowedDay = useStore((state) => state.addAllowedDay);
  const removeAllowedDay = useStore((state) => state.removeAllowedDay);

  const handleSelectDay = (e, day) => {
    day = String(day);
    if (allowedDays.includes(day)) {
      removeAllowedDay(day);
      return;
    }
    addAllowedDay(String(day));
  };

  return (
    <ul>
      {items.map((item, idx) => (
        <li
          key={item.id}
          onClick={(e) => handleSelectDay(e, idx)}
          className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer"
        >
          <span className="flex justify-between items-center font-medium text-titles-300 hover:opacity-100  rounded-md">
            <span
              className={`${
                allowedDays.includes(String(idx)) ? "text-amber-800" : ""
              }`}
            >
              {item.title}
            </span>
            <span>
              {allowedDays.includes(String(idx)) ? (
                <span className="shrink-0 text-white">
                  <CheckIcon className="h-5 w-5" />
                </span>
              ) : (
                ""
              )}
            </span>
          </span>
        </li>
      ))}
    </ul>
  );
}
