const categoriesArray = {
  Days: [
    {
      id: 1,
      title: "Sunday",
    },
    {
      id: 2,
      title: "Monday",
    },
    {
      id: 3,
      title: "Tuesday",
    },
    {
      id: 4,
      title: "Wednesday",
    },
    {
      id: 5,
      title: "Thursday",
    },
    {
      id: 6,
      title: "Friday",
    },
    {
      id: 7,
      title: "Saturday",
    },
  ],
  Slots: [
    {
      id: 1,
      title: "Is tech making coffee better or worse?",
      date: "Jan 7",
      commentCount: 29,
      shareCount: 16,
    },
    {
      id: 2,
      title: "The most innovative things happening in coffee",
      date: "Mar 19",
      commentCount: 24,
      shareCount: 12,
    },
  ],
  Customize: [
    {
      id: 1,
      title: "Ask Me Anything: 10 answers to your questions about coffee",
      date: "2d ago",
      commentCount: 9,
      shareCount: 5,
    },
    {
      id: 2,
      title: "The worst advice we've ever heard about coffee",
      date: "4d ago",
      commentCount: 1,
      shareCount: 2,
    },
  ],
};

export default categoriesArray;
