import React from "react";

export default function DayStatus({ userTeam }) {
  return (
    <>
      <div
        style={{
          fontFamily: "poppins",
          backgroundColor: "#f8fafb49",
          marginTop: "0.05rem",
        }}
        className="flex justify-between items-center font-medium text-titles-300 text-lg py-6 px-4 border-b-2"
      >
        <div>Today's brief</div>
        {/* <div
          className="cursor-pointer hover:text-amber-800 hover:bg-gray-100 opacity-80 hover:opacity-100 p-1 rounded-md"
          onClick={() => setSelectedMembers({})}
        >
          <FiUserX size={16} />
        </div> */}
      </div>
    </>
  );
}
