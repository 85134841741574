import { useState } from "react";
import Table from "../../components/Table";
import { useStore } from "../../hooks/useStore";
// import SideTable from "../Dashboard/SideTable";
import InviteMember from "./InviteMember";
import CreateTeam from "./CreateTeam";
import MyDialog from "../../components/shared/Modal";
import { TiUserAddOutline } from "react-icons/ti";

const Team = ({ user, userTeam, dispatch }) => {
  const [stale, setStale] = useState({ stale: false });
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const teamOwnerId = useStore((state) => state.teamOwnerId);
  const isUserTeamOwner = teamOwnerId.includes(user?.$id) || false;
  // console.log("userTeam", userTeam);

  const handleAddMember = () => {
    setAddMemberOpen(true);
  };

  return (
    <>
      {!userTeam?.$id ? (
        <section className="h-screen flex">
          <div className="w-2/4 ml-10">
            <CreateTeam />
          </div>
          <div className="w-2/4 h-full">
            <iframe
              title="team"
              width="100%"
              height="100%"
              src="https://embed.lottiefiles.com/animation/81450"
            ></iframe>
          </div>
        </section>
      ) : (
        <>
          <div
            style={{ fontFamily: "poppins" }}
            className="w-100 pt-8 pb-4 px-10 flex items-center justify-between"
          >
            <div>
              <h1 className="text-4xl font-bold text-titles-800 tracking-wide">
                Team {userTeam?.name}
              </h1>
              {userTeam.sum > 1 && (
                <div className="ml-1">{userTeam.sum} Active Members</div>
              )}
            </div>
            {isUserTeamOwner && (
              <div>
                <button
                  onClick={handleAddMember}
                  className="flex text-darkYellow bg-gradient-to-r from-amber-200 via-amber-400 to-amber-500 hover:text-white hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Add Member
                </button>
              </div>
            )}
          </div>
          <section className="flex">
            {/* {isUserTeamOwner && (
              <div className="w-3/12 ">
                <InviteMember
                  dispatch={dispatch}
                  userTeam={userTeam}
                  setStale={setStale}
                />
              </div>
            )} */}

            <div className="w-9/12 flex-grow py-6 px-8 ">
              {userTeam && <Table userTeam={userTeam} stale={stale} />}
            </div>
          </section>
          <MyDialog
            isModalOpen={addMemberOpen}
            openModal={setAddMemberOpen}
            modalContent={
              <InviteMember
                dispatch={dispatch}
                userTeam={userTeam}
                setStale={setStale}
              />
            }
            modalTitle={"Add Member"}
            buttonText={"Apply"}
            icon={<TiUserAddOutline size={20} />}
            showButton={false}
          />
        </>
      )}
    </>
  );
};

export default Team;
