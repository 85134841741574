import { useState, useEffect } from "react";
import api from "../../api/api";
import SignUp from "./SignUp";
import useQuery from "../../hooks/useQuery";

const LoginFromInvite = ({ dispatch }) => {
  const query = useQuery();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  // eslint-disable-next-line
  const [seePassword, setSeePassword] = useState("password");
  const [loading, setLoading] = useState(false);
  const [tempSecret, setTempSecret] = useState("");
  const [tempUserId, setTempUserId] = useState("");
  const [tempTeamId, setTempTeamId] = useState("");
  const [tempMembershipId, setTempMembershipId] = useState("");

  const [register, setRegister] = useState(false);

  useEffect(() => {
    const handleLoginFromInvatation = async () => {
      const secret = String(query.get("secret").replace(/\s/g, ""));
      const userId = String(query.get("userId").replace(/\s/g, ""));
      const teamId = String(query.get("teamId").replace(/\s/g, ""));
      const membershipId = String(query.get("membershipId").replace(/\s/g, ""));

      setTempSecret(secret);
      setTempUserId(userId);
      setTempTeamId(teamId);
      setTempMembershipId(membershipId);
    };

    if (query.get("teamId") && query.get("secret") && query.get("userId")) {
      handleLoginFromInvatation();
    }
  }, [query, dispatch]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const newMember = await api.acceptInvaiteFromEmail(
        tempTeamId,
        tempMembershipId,
        tempUserId,
        tempSecret
      );
      const passwordIsSet = await api.updateUserPassword(password);
      setTimeout(() => {
        if (newMember && passwordIsSet) {
          window.location.reload();
          setLoading(false);
        }
      }, 2000);
    } catch (e) {
      if (e.response.code === 409) {
        console.log("exepted invite", e.response.code);
      }
    }
  };

  //   console.log("tempSecret", tempSecret);
  //   console.log("tempUserId", tempUserId);
  //   console.log("tempTeamId", tempTeamId);
  //   console.log("tempMembershipId", tempMembershipId);

  return register ? (
    <SignUp setRegister={setRegister} dispatch={dispatch} />
  ) : (
    // <section style={{ fontFamily: "poppins" }}>
    //   <div className="h-screen flex flex-row ">
    //     <div className="w-1/4 flex-grow flex flex-col max-w-xl justify-center p-5">
    //       <h1 className="text-5xl font-bold ">
    //         {" "}
    //         You've been invited to join the team!
    //       </h1>

    //       <form onSubmit={handleLogin}>
    //         <label className="block mt-6">Choose Password</label>
    //         <input
    //           className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
    //           type={seePassword}
    //           onChange={(e) => setPassword(e.target.value)}
    //         />
    //         <label className="block mt-6">Confirm Password</label>
    //         <input
    //           className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
    //           type={seePassword}
    //           onChange={(e) => setPasswordConfirmation(e.target.value)}
    //         />

    //         <div className="mt-6">
    //           <button
    //             type="submit"
    //             disabled={
    //               password.length < 7 || password !== passwordConfirmation
    //             }
    //             className="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
    //           >
    //             {loading ? "loading..." : "Continue"}
    //           </button>
    //         </div>
    //       </form>
    //     </div>
    //     <div className="w-3/4 h-screen flex border loginBackground"></div>
    //   </div>
    // </section>

    <div style={{ fontFamily: "poppins" }} class="h-screen md:flex">
      <div
        id="login-side"
        class="relative overflow-hidden md:flex w-1/2 bg-gradient-to-tr from-brunch to-amber2-700 i justify-around items-center hidden"
      >
        <div>
          <h1 class="text-white font-bold text-4xl font-sans">
            Welcome aboard!
          </h1>
          <p class="text-white mt-1">
            You've been invited to join you're team.
          </p>
          {/* <button
            type="submit"
            class="block w-28 bg-white text-indigo-800 mt-4 py-2 rounded-2xl font-bold mb-2"
          >
            Sign up
          </button> */}
        </div>
        <div class="absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
        <div class="absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
        <div class="absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
        <div class="absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
      </div>

      <div class="flex flex-col md:w-1/2 justify-center py-10 items-center bg-white">
        <div className="mt-4">
          <img
            width="45px"
            src={
              "https://res.cloudinary.com/nadlanitor-cloud/image/upload/v1689025787/weeklyz.pro/weeklyz_logo_3_colmhg.svg"
            }
            alt=""
          />
        </div>
        <form onSubmit={handleLogin} class="bg-white">
          <h1 class="text-gray-800 font-bold text-2xl mb-1">
            Choose a password
          </h1>
          <p class="text-sm font-normal text-gray-600 mb-7"></p>

          <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none"
              name=""
              id=""
              placeholder="Password"
              type={seePassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div class="flex items-center border-2 mt-3 py-2 px-3 rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none"
              name=""
              id=""
              placeholder="Confirm Password"
              type={seePassword}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          <button
            type="submit"
            class="block w-full bg-brunch mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
            disabled={password.length < 7 || password !== passwordConfirmation}
          >
            {loading ? "Please wait..." : "Login"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginFromInvite;
