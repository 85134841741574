import {
  Client as Appwrite,
  Databases,
  Account,
  Teams,
  Query,
  Permission,
  Role,
} from "appwrite";
import { Server } from "../utils/config";

const url = "https://app.weeklyz.pro/login-from-invite/";
// const url = "http://localhost:3002/login-from-invite/";
const databaseId = process.env.REACT_APP_DATABASE_ID;

let api = {
  sdk: null,

  provider: () => {
    if (api.sdk) {
      return api.sdk;
    }
    let appwrite = new Appwrite();
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
    const account = new Account(appwrite);
    const database = new Databases(appwrite);
    const teams = new Teams(appwrite);

    api.sdk = { database, account, teams };
    return api.sdk;
  },

  acceptInvaiteFromEmail: (teamId, membershipId, userId, secret) => {
    return api
      .provider()
      .teams.updateMembershipStatus(
        String(teamId),
        String(membershipId),
        String(userId),
        String(secret)
      );
  },
  updateUserPassword: (password) => {
    return api.provider().account.updatePassword(password);
  },

  createAccount: (email, password, name) => {
    //WORKS
    return api.provider().account.create("unique()", email, password, name);
  },

  getAccount: () => {
    // WORKS
    let account = api.provider().account;
    return account.get();
  },

  createSession: (email, password) => {
    // WORKS
    return api.provider().account.createEmailSession(email, password);
  },
  createGitOauthSession: () => {
    return api.provider().account.createOAuth2Session("github");
  },

  deleteCurrentSession: () => {
    // WORKS
    return api.provider().account.deleteSessions();
  },

  getUserTeam: () => {
    //WORKS
    return api.provider().teams.list();
  },

  getTeamMemberships: (teamId) => {
    //WORKS
    return api.provider().teams.listMemberships(String(teamId));
  },

  listDocuments: (collectionId, teamId) => {
    //WORKS
    return api
      .provider()
      .database.listDocuments(databaseId, collectionId, [
        Query.equal("teamId", teamId),
        Query.limit(100),
      ]);
  },

  inviteTeamMember: (teamId, userEmail, name) => {
    //WORKS
    console.log("InviteTeamMember", teamId, userEmail, name);
    return api
      .provider()
      .teams.createMembership(
        teamId,
        ["member"],
        url,
        userEmail,
        "uniqe()",
        "",
        name
      );
  },

  createNewTeam: (teamName) => {
    // WORKS
    return api.provider().teams.create("unique()", teamName);
  },

  updateUserPrefs: (userPrefs) => {
    // WORKS
    return api.provider().account.updatePrefs({ userPrefs });
  },

  deleteDocument: (collectionId, documentId) => {
    // WORKS
    console.log("deleteDocument", databaseId, collectionId, documentId);
    return api
      .provider()
      .database.deleteDocument(databaseId, collectionId, documentId);
  },

  createDocument: (collectionId, data, teamId, userId) => {
    // WORKS
    return api
      .provider()
      .database.createDocument(databaseId, collectionId, "unique()", data, [
        Permission.read(Role.team(teamId)), // Only users of this team can read the document
        Permission.update(Role.user(userId)), // Only creator of document can update the document
        Permission.delete(Role.user(userId)), // Only creator of document can delete the document
      ]);
  },

  createTeamSettings: (collectionId, data, teamId, userId) => {
    // WORKS
    console.log("createTeamSettings", collectionId, data, teamId, userId);
    return api
      .provider()
      .database.createDocument(databaseId, collectionId, "unique()", data, [
        Permission.read(Role.team(teamId)), // Only users of team 5c1f88b87435e can read the document
        Permission.update(Role.team(teamId, "owner")), // Only users of team 5c1f88b87435e with the role owner can update the document
        Permission.delete(Role.user(userId)), // Only users of team 5c1f88b87435e with the role owner can delete the document
      ]);
  },
  updateTeamSettings: (collectionId, teamSettingsId, data) => {
    // WORKS
    return api
      .provider()
      .database.updateDocument(databaseId, collectionId, teamSettingsId, data);
  },

  getTeamSettings: (teamId, collectionId) => {
    // WORKS
    console.log("getTeamSettings", collectionId, "teamId", teamId);
    return api
      .provider()
      .database.listDocuments(
        databaseId,
        collectionId,
        [Query.equal("teamId", [teamId])],
        1
      );
  },
};

export default api;
