import React from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { useStore } from "../hooks/useStore";

const BigCalendar = ({
  events,
  eventClick,
  isSelectAllow,
  handleMonthViewChange,
}) => {
  const allowedDays = useStore((state) => state.allowedDays);
  const USER_DAYS_OF_WEEK = allowedDays || [(0, 1, 2, 3, 4, 5, 6)];

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        buttonText={{ today: "Today" }}
        events={events}
        eventClick={eventClick}
        selectable={true}
        selectAllow={(selectInfo) => isSelectAllow(selectInfo)}
        businessHours={{ daysOfWeek: USER_DAYS_OF_WEEK }}
        height={"100%"}
        datesSet={handleMonthViewChange}
        customButtons={{
          demo: {
            text: "custom!",
            click: function () {
              alert("clicked the custom button!");
            },
          },
        }}
        headerToolbar={{
          left: "title",
          center: "",
          right: "prev,next today",
        }}
      />
    </>
  );
};

export default BigCalendar;
