import React, { useState } from "react";
import Lottie from "lottie-react";
import dog from "../../animations/dog.json";
import SettingsTabs from "../../components/shared/SettingsTabs";
import { useHistory } from "react-router-dom";

export default function Settings({ userTeam, setIsModalOpen, user }) {
  const history = useHistory();
  const [waitForTeam] = useState(!userTeam?.$id);

  console.log("waitForTeam", userTeam);

  const goTo = (path) => {
    history.push(`/${path}`);
  };

  if (!waitForTeam) {
    return <SettingsTabs teamatesAmount={userTeam?.total} user={user} />;
  } else {
    return (
      <>
        <div
          id="settings"
          className="flex flex-col mx-auto justify-center text-center mb-20"
        >
          <div>
            <Lottie
              style={{ height: "300px" }}
              loop={true}
              autoplay={true}
              animationData={dog}
            />
          </div>
          <div
            style={{ fontFamily: "poppins" }}
            className="mt-2 font-medium cursor-pointer"
            onClick={() => {
              goTo("team");
              setIsModalOpen(false);
            }}
          >
            Set up your team first!
          </div>
        </div>
      </>
    );
  }
}
