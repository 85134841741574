import { Switch } from "@headlessui/react";

export default function Toggle({ label, isOn, setOn }) {
  return (
    <Switch.Group>
      <div className="flex items-center ml-3">
        <Switch.Label className="mr-4 font-medium">{label}</Switch.Label>
        <Switch
          checked={isOn}
          onChange={setOn}
          className={`${
            isOn ? "bg-amber-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors  focus:ring-offset-2`}
        >
          <span
            className={`${
              isOn ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
