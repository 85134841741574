import React from "react";
import RadioGroupMenu from "../../components/shared/RadioGroup";
import { useGetTeamMembers } from "../../hooks";
import { useStore } from "../../hooks/useStore";

export default function SideTable({ userTeam }) {
  const [{ teamMembers }] = useGetTeamMembers(false, userTeam.$id);
  const selectedMembers = useStore((state) => state.selectedMembers);
  const setSelectedMembers = useStore((state) => state.setSelectedMembers);
  const memberships = teamMembers?.memberships || [];
  const membershipsNum = teamMembers?.memberships?.length || 0;

  return (
    <>
      <div
        style={{
          fontFamily: "poppins",
          backgroundColor: "#f8fafb49",
          marginTop: "0.05rem",
        }}
        className="flex justify-between items-center font-medium text-lg py-4 px-4 border-b-2"
      >
        <div className="small-title">{`Teamates (${membershipsNum})`} </div>
        {/* <div
          className="cursor-pointer hover:text-amber-800 hover:bg-gray-100 opacity-80 hover:opacity-100 p-1 rounded-md"
          onClick={() => setSelectedMembers({})}
        >
          <FiUserX size={16} />
        </div> */}
      </div>
      <RadioGroupMenu
        data={memberships}
        selected={selectedMembers}
        setMethod={setSelectedMembers}
      />
    </>
  );
}
