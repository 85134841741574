import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CgClose } from "react-icons/cg";

export default function MyModal({
  isModalOpen = true,
  openModal,
  modalContent,
  modalTitle,
  buttonText,
  onSubmit,
  showButton,
  icon,
}) {
  return (
    <>
      <Transition
        style={{ fontFamily: "poppins" }}
        appear
        show={isModalOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => openModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex align-center items-center text-lg font-medium leading-6 text-titles-800"
                  >
                    <span className="mr-3 mt-1">{icon}</span>
                    {modalTitle}
                    <div
                      onClick={() => openModal(false)}
                      className="ml-auto cursor-pointer"
                    >
                      <CgClose />
                    </div>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{modalContent}</p>
                  </div>

                  {showButton && (
                    <div className="flex flex-row-reverse mt-4">
                      <button
                        type="button"
                        className="justify-center inline-flex justify-center rounded-md  border-2 border-gray-400 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:border-amber-800 hover:text-amber-800"
                        onClick={() => {
                          onSubmit();
                          openModal();
                        }}
                      >
                        {buttonText}
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
