import { useState } from "react";
import api from "../../api/api";

const InviteMember = ({ dispatch, userTeam, setStale }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleInviteMember = async (e) => {
    e.preventDefault();
    console.log("1", userTeam.$id);
    console.log("2", email);
    console.log("3", name);
    try {
      await api.inviteTeamMember(userTeam.$id, email, name);
      setName("");
      setEmail("");
      setStale(true);
    } catch (e) {
      console.log("error inviting Member", e);
    }
  };

  return (
    <>
      <section style={{ fontFamily: "poppins" }}>
        <div className="flex flex-row ">
          <div className="flex-grow flex flex-col p-5">
            <h1 className="text-3xl text-titles-800 font-bold ">
              Invite {userTeam.name} team members to join
            </h1>

            <form onSubmit={handleInviteMember}>
              <label className="block mt-6">Full Name</label>
              <input
                className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <label className="block mt-6"> Email</label>
              <input
                className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={!name || !email}
                  className="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-brunch focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Send Invite
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default InviteMember;
