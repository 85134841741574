import { useState } from "react";
import api from "../../api/api";
import SignUp from "./SignUp";
import { FetchState } from "../../hooks";

const Login = ({ dispatch, setStale }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [register, setRegister] = useState(false);

  const handleLogin = async (e) => {
    console.log(`Login ${email}`);
    e.preventDefault();
    dispatch({ type: FetchState.FETCH_INIT });
    try {
      await api.createSession(email, password);
      const data = await api.getAccount();
      dispatch({ type: FetchState.FETCH_SUCCESS, payload: data });
      setStale({ stale: true });
    } catch (e) {
      dispatch({ type: FetchState.FETCH_FAILURE });
    }
  };

  return register ? (
    <SignUp setRegister={setRegister} dispatch={dispatch} />
  ) : (
    <>
      {/* <section style={{ fontFamily: "poppins" }}>
        <div className="h-screen flex flex-row ">
          <div className="w-1/4 flex-grow flex flex-col max-w-xl justify-center p-5">
            <h1 className="text-5xl font-bold ">Login</h1>
            <p className="mt-6">
              {" "}
              Don't have an account ?{" "}
              <span
                className="cursor-pointer underline"
                onClick={() => setRegister(true)}
              >
                Sign Up
              </span>{" "}
            </p>
            <form onSubmit={handleLogin}>
              <label className="block mt-6"> Email</label>
              <input
                className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label className="block mt-6"> Password</label>
              <input
                className="w-full p-4 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <div className="mt-6">
                <button
                  type="submit"
                  disabled={!email || !password}
                  className="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-brunch focus:outline-none"
                >
                  Login
                </button>
              </div>
            </form>
          </div>
          <div className="w-3/4 h-screen flex border loginBackground"></div>
        </div>
      </section> */}

      <div style={{ fontFamily: "poppins" }} class="h-screen md:flex">
        <div
          id="login-side"
          class="relative overflow-hidden md:flex w-1/2 bg-gradient-to-tr from-amber2-700 to-brunch  i justify-around items-center hidden"
        >
          <div>
            <h1 class="text-white font-bold text-4xl font-sans">Weeklyz</h1>
            <p class="text-white mt-1">
              Synchronize your hybrid team schedule.
            </p>
            <button
              type="submit"
              class="block w-28 bg-white text-indigo-800 mt-4 py-2 rounded-2xl font-bold mb-2"
              onClick={() => setRegister(true)}
            >
              Sign up
            </button>
          </div>
          <div class="absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
          <div class="absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
          <div class="absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
          <div class="absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
        </div>
        <div class="flex md:w-1/2 justify-center py-10 items-center bg-white">
          <form onSubmit={handleLogin} class="bg-white">
            <h1 class="text-gray-800 font-bold text-2xl mb-1">Welcome Back!</h1>
            <p class="text-sm font-normal text-gray-600 mb-7">Sign in</p>

            <div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
              <input
                class="pl-2 outline-none border-none"
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
              <input
                class="pl-2 outline-none border-none"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <button
              type="submit"
              class="block w-full bg-brunch mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
              disabled={!email || !password}
            >
              Login
            </button>
            {/* <span class="text-sm ml-2 hover:text-blue-500 cursor-pointer">
              Forgot Password ?
            </span> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
