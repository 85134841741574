import React, { useEffect } from "react";
import { AiOutlineAlert } from "react-icons/ai";

const Alert = ({ message, setWarningMessage }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  const TIMEOUT = 2000;

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowAlert(false);
      setWarningMessage("");
    }, TIMEOUT);
    return () => {
      clearTimeout(timeout);
    };
  }, [setWarningMessage]);

  return (
    <>
      {showAlert && (
        <div
          style={{ fontFamily: "poppins" }}
          onClick={() => {
            setShowAlert(false);
          }}
          className="absolute bottom-0 right-0 mt-4 mr-5 p-2 cursor-pointer  z-20"
        >
          <div className="inline-flex items-center bg-white text-black rounded-full p-2 shadow text-teal text-sm ">
            <span className="inline-flex bg-brunch text-white rounded-full h-6 px-3 justify-center items-center">
              <AiOutlineAlert />
            </span>
            <span className="inline-flex px-6">{message}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Alert;
