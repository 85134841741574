import { useState } from "react";
import api from "../../api/api";
import { FetchState } from "../../hooks";
import { useHistory } from "react-router-dom";

const CreateTeam = ({ user, dispatch }) => {
  const history = useHistory();
  const [name, setName] = useState("");

  const handleTeamCreation = async (e) => {
    e.preventDefault();
    try {
      const teamIsReady = await api.createNewTeam(name);
      console.log("teamIsReady", teamIsReady);
      if (teamIsReady?.$id) {
        history.push(`/dashboard/?setup=${teamIsReady.name}`);
      }
    } catch (e) {
      dispatch({ type: FetchState.FETCH_FAILURE });
    }
  };

  return (
    <>
      <section className="container h-screen mx-auto flex">
        <div
          style={{ fontFamily: "poppins" }}
          className="flex-grow flex flex-col max-w-lg justify-center p-8"
        >
          <h1 className="text-5xl font-bold">name your team</h1>
          {/* <h4 className="text-1xl mt-4 text-gray-600 font-serif">
            description helper
          </h4> */}

          <form onSubmit={handleTeamCreation}>
            {/* <label className="block mt-6">Team Name</label> */}
            <input
              className="w-full p-4 mt-6 placeholder-gray-400 text-gray-700 bg-white text-lg border-0 border-b-2 border-gray-400 focus:ring-0 focus:border-gray-900"
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder="Weeklyz R&D"
            />

            <div className="mt-6">
              <button
                type="submit"
                disabled={!name}
                className="mx-auto mt-4 py-4 px-16 font-semibold rounded-lg shadow-md bg-gray-900 text-white border hover:border-gray-900 hover:text-gray-900 hover:bg-white focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default CreateTeam;
