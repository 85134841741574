import { Tab } from "@headlessui/react";
import categoriesArray from "../../constants/settingsCategories";
import DaysTab from "../../pages/Settings/DaysTab";
import SelectBox from "./SelectBox";
import Toggle from "./Toggle";
import { useStore } from "../../hooks/useStore";
// import ImageUploaderEditor from "./ImageUploaderEditor";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsTabs({ teamatesAmount, user }) {
  const pastDaysToggle = useStore((state) => state.pastDaysToggle);
  const togglePastDaysToggle = useStore((state) => state.togglePastDaysToggle);
  const teamOwnerId = useStore((state) => state.teamOwnerId);
  const isUserTeamOwner = teamOwnerId.includes(user?.$id) || false;

  if (isUserTeamOwner) {
    return (
      <div
        style={{ fontFamily: "poppins" }}
        className="w-full max-w-md px-2 py-2 sm:px-0"
      >
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            {Object.keys(categoriesArray).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-titles-800",
                    "ring-white ring-opacity-60 focus:outline-none focus:ring-2",
                    selected
                      ? "bg-amber-400 shadow"
                      : "text-blue-100  hover:text-brunch"
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel
              as="div"
              key={1}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60  focus:outline-none focus:ring-2"
              )}
            >
              <div className="py-2 px-2 font-medium">
                allowed days - select the days the office is attendable
              </div>
              <DaysTab />
            </Tab.Panel>
            <Tab.Panel
              as="div"
              key={2}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60  focus:outline-none focus:ring-2 "
              )}
            >
              <div className="py-2 px-2 font-medium">
                Daily slots - How many open slots every day
              </div>
              <SelectBox teamatesAmount={teamatesAmount} />
            </Tab.Panel>
            <Tab.Panel
              as="div"
              key={3}
              className={classNames(
                "rounded-xl bg-white p-3",
                "ring-white ring-opacity-60  focus:outline-none focus:ring-2"
              )}
            >
              <div className="p-2">
                <Toggle
                  isOn={pastDaysToggle}
                  setOn={togglePastDaysToggle}
                  label={"Attend to past dates"}
                />
              </div>
              {/* <div className="font-medium mt-4">
                Team Logo (Upload only 120*72 backgroundless logo's.)
              </div>
              <ImageUploaderEditor /> */}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  } else {
    return "User Settings";
  }
}
