import React, { useState, useEffect } from "react";
import BigCalendar from "../../components/BigCalendar";
import api from "../../api/api";
import { Server } from "../../utils/config";
import { useGetEvents } from "../../hooks";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import loader from "../../animations/loader.json";
import MONTH_NUM from "../../constants/month";
import { isDateInThePast } from "../../helpers/isDateInThePast";
import { isUserInThisDay } from "../../helpers/isUserInThisDay";
import { isDayFull } from "../../helpers/isDayFull";
import { useStore } from "../../hooks/useStore";
import SideTable from "./SideTable";
import { isEmpty } from "lodash";
import { isDayAllowed } from "../../helpers/isDayAllowed";
import Alert from "../Alert/Alert";
import { isDayInMonthView } from "../../helpers/isDayInMonthView";
import useQuery from "../../hooks/useQuery";
import DayStatus from "./DayStatus";
import { Client } from "appwrite";

const Dashboard = ({ user, userTeam, stale, loading }) => {
  const client = new Client()
    .setEndpoint("https://cloud.appwrite.io/v1")
    .setProject(process.env.REACT_APP_PROJECT_PROD);

  const query = useQuery();
  const history = useHistory();

  const [todayStats] = useState(false);
  const selectedMembers = useStore((state) => state.selectedMembers);
  const allowedDays = useStore((state) => state.allowedDays);
  const slotsAmount = useStore((state) => state.slotsAmount);
  const pastDaysToggle = useStore((state) => state.pastDaysToggle);
  const date = new Date();
  let month = MONTH_NUM[date.getMonth()];
  const [eventsStale, setEventsStale] = useState({ eventsStale: false });
  const [viewMonth, setViewMonth] = useState(month);
  const [eventsArray, setEventsArray] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [{ events, isLoadingEvents }] = useGetEvents(
    eventsStale,
    userTeam.$id,
    viewMonth
  );

  // console.log("userTeam", userTeam);
  // console.log("user", user);

  console.log("events", eventsArray.length);

  useEffect(() => {
    if (!isLoadingEvents && userTeam) {
      const eventsToEventsArray = events.map((event) => {
        return {
          title: nameShortner(event.title),
          name: event.title,
          date: event.date,
          month: event.month,
          color: event.color,
          teamId: event.teamId,
          userId: event.userId,
          $id: event.$id,
        };
      });
      setEventsArray(eventsToEventsArray);
    }
  }, [events, isLoadingEvents, userTeam, stale, selectedMembers]);

  useEffect(() => {
    const unsubscribe = client.subscribe(
      `databases.${process.env.REACT_APP_DATABASE_ID}.collections.${process.env.REACT_APP_EVENTS_COLLECTION_ID_PROD}.documents`,
      (response) => {
        if (response.events[0].includes("create")) {
          setEventsArray((eventsArray) => [...eventsArray, response.payload]);
        }
        if (response.events[0].includes("delete")) {
          setEventsStale(false);
          setEventsStale(true);
        }
      }
    );

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const setup = String(query.get("setup"));
    if (setup !== "null") {
      window.location.reload();
    }
  }, [query]);

  const filterdEvents = () => {
    if (isEmpty(selectedMembers)) {
      return eventsArray;
    } else {
      const filterd = eventsArray.filter(
        (event) => selectedMembers.userId === event.userId
      );
      return filterd;
    }
  };

  const goTo = (path) => {
    history.push(`/${path}`);
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      await api.deleteDocument(Server.eventsCollectionID, eventId);
      setEventsStale({ stale: true });
    } catch (e) {
      console.log("Error in removing event");
    }
  };

  const handleMonthViewChange = (e) => {
    const selectedMonth = e.endStr.slice(5, 7);
    const selectedMonthNumber = Number(selectedMonth) - 2;
    const selectedMonthString =
      MONTH_NUM[selectedMonthNumber === -1 ? 11 : selectedMonthNumber];
    setViewMonth(selectedMonthString);
  };

  const isSelectAllow = (selectInfo) => {
    if (isUserInThisDay(selectInfo, eventsArray, user)) {
      setWarningMessage("You're allready in this day");
      return;
    }
    if (!pastDaysToggle && isDateInThePast(selectInfo)) {
      setWarningMessage("This day is in the past");
      return;
    }
    if (isDayFull(selectInfo, eventsArray, slotsAmount)) {
      setWarningMessage("This day is Full");
      return;
    }
    if (isDayAllowed(selectInfo, allowedDays)) {
      setWarningMessage("This day is not allowed");
      return;
    }
    if (isDayInMonthView(selectInfo, viewMonth)) {
      setWarningMessage("Selected day is not in this month");
      return;
    }

    handleAddEvent(selectInfo.startStr);
  };

  const handleEventClick = (e) => {
    const eventId = e.event._def.extendedProps.$id;
    handleDeleteEvent(eventId);
  };

  const nameShortner = (name) => {
    if (name.includes(" ")) {
      const first = name.split(" ")[0];
      const last = name.split(" ")[1].charAt(0).toUpperCase();
      const shorten = `${first} .${last}`;

      return shorten;
    }
    return name;
  };

  const handleAddEvent = async (date) => {
    const data = {
      title: user.name,
      date: date,
      color: user.prefs.userPrefs.userColor,
      userId: user.$id,
      teamId: userTeam.$id,
      month: date.slice(5, 7),
    };

    try {
      await api.createDocument(
        Server.eventsCollectionID,
        data,
        userTeam.$id,
        user.$id
      );
      setEventsStale({ stale: true });
    } catch (e) {
      console.log("Error in adding event");
    }
  };

  return (
    <>
      <section className="flex h-10">
        {warningMessage && (
          <Alert
            message={warningMessage}
            setWarningMessage={setWarningMessage}
          />
        )}
        {userTeam?.$id && (
          <div
            id="teamates-list"
            style={{ height: "calc(100vh - 60px)" }}
            className="w-0 xl:w-2/12 ml-auto overflow-auto border-r-2"
          >
            <SideTable userTeam={userTeam} />
          </div>
        )}

        <div
          id="calendar"
          style={{ height: "calc(100vh - 60px)" }}
          className="w-full ml-auto overflow-hidden px-8"
        >
          {!loading ? (
            <>
              {!isLoadingEvents && userTeam?.$id ? (
                <BigCalendar
                  events={filterdEvents()}
                  eventClick={(e) => handleEventClick(e)}
                  isSelectAllow={isSelectAllow}
                  handleMonthViewChange={handleMonthViewChange}
                />
              ) : (
                <section
                  style={{ fontFamily: "poppins" }}
                  className="container h-screen mx-auto flex"
                >
                  <div className="flex flex-col mx-auto justify-center text-center mb-20 ">
                    <div>
                      <iframe
                        title="confeti"
                        width="100%"
                        height="200px"
                        src="https://embed.lottiefiles.com/animation/78972"
                      ></iframe>
                    </div>
                    <p className=" text-xl md:text-2xl lg:text-2xl font-medium">
                      Hey there chief, Let's get started!
                    </p>
                    <button
                      onClick={() => goTo("team")}
                      className="mx-auto mt-4 py-2 lg:py-3 px-5 lg:px-5 text-lg md:text-1xl font-semibold  rounded-lg shadow-md bg-white text-gray-900 border border-gray-900 hover:border-transparent hover:text-white hover:bg-brunch focus:outline-none"
                    >
                      Set Up Team
                    </button>
                  </div>
                </section>
              )}
            </>
          ) : (
            <section
              style={{ fontFamily: "poppins" }}
              className="container h-screen w-5/6"
            >
              <div className="flex flex-col mx-auto justify-center ml-auto mt-24 ">
                <div>
                  <Lottie
                    style={{ height: "300px" }}
                    loop={true}
                    autoplay={true}
                    animationData={loader}
                  />
                </div>
              </div>
            </section>
          )}
        </div>
        {todayStats && (
          <div
            style={{ height: "calc(100vh - 60px)" }}
            className="w-0 xl:w-3/12 mr-auto overflow-auto border-l-2"
          >
            <DayStatus />
          </div>
        )}
      </section>
    </>
  );
};

export default Dashboard;
