import React, { useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Landing from "./pages/Landing/Landing";
import { useGetTeam, useGetUser } from "./hooks";
import TheHeader from "./containers/TheHeader";
import Team from "./pages/Team/Team";
import { useStore } from "./hooks/useStore";
import LoginFromInvite from "./pages/Login/LoginFromInvite";
import { USER_COLORS } from "./constants/userColors";
import api from "./api/api";

import MyDialog from "./components/shared/Modal";
import Settings from "./pages/Settings/Settings";
import { Server } from "./utils/config";
import { FiSettings } from "react-icons/fi";
import { HiOutlineUserCircle } from "react-icons/hi";
import Tour from "reactour";
import steps from "./constants/tour_steps";
import ImageUploaderEditor from "./components/shared/ImageUploaderEditor";
import storageApi from "./api/storageApi";

function App() {
  const [stale, setStale] = useState({ stale: false });
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [{ user, isLoading }, dispatch] = useGetUser(stale);
  const [{ team, isLoadingTeam }] = useGetTeam(stale);

  // console.log("user", user);
  // console.log("team", team);

  const userTeam = !isLoadingTeam && team?.teams[0] ? team?.teams[0] : {};
  const isModalOpen = useStore((state) => state.isModalOpen);
  const setIsModalOpen = useStore((state) => state.setIsModalOpen);
  const isAvatarModalOpen = useStore((state) => state.isAvatarModalOpen);
  const setIsAvatarModalOpen = useStore((state) => state.setIsAvatarModalOpen);
  const allowedDays = useStore((state) => state.allowedDays);
  const pastDaysToggle = useStore((state) => state.pastDaysToggle);
  const slotsAmount = useStore((state) => state.slotsAmount);
  const setSlots = useStore((state) => state.setSlots);
  const setAllowedDays = useStore((state) => state.setAllowedDays);
  const setPastDaysToggle = useStore((state) => state.setPastDaysToggle);
  const setTeamSettingsId = useStore((state) => state.setTeamSettingsId);
  const teamSettingsId = useStore((state) => state.teamSettingsId);
  const setTeamOwnerId = useStore((state) => state.setTeamOwnerId);
  const editedImage = useStore((state) => state.editedImage);
  const setEditedImage = useStore((state) => state.setEditedImage);
  const setTeamAvatars = useStore((state) => state.setTeamAvatars);
  const setTeamLogoId = useStore((state) => state.setTeamLogoId);
  const teamLogoId = useStore((state) => state.teamLogoId);

  useEffect(() => {
    const getTeamSettings = async () => {
      try {
        const data = await api.getTeamSettings(
          userTeam.$id,
          Server.settingsCollectionID
        );
        console.log("data: ", data);
        if (data.total < 1) {
          setTeamOwnerId(user.$id);
          setIsTourOpen(true);
        } else {
          setSlots(data?.documents[0].slots);
          setPastDaysToggle(data?.documents[0].pastDaysToggle);
          setAllowedDays(data?.documents[0].allowedDays);
          setTeamSettingsId(data?.documents[0].$id);
          setTeamOwnerId(extractIdFromArray(data?.documents[0].$permissions));
          setTeamLogoId(data?.documents[0].teamLogoId);
        }
      } catch (e) {
        console.log("error fetching team settings");
      }
    };
    if (userTeam?.$id) {
      getTeamSettings();
    }
    // eslint-disable-next-line
  }, [userTeam]);

  function extractIdFromArray(array) {
    const regex = /delete\("user:(\w+)"\)/;

    for (let i = 0; i < array.length; i++) {
      const matches = array[i].match(regex);
      if (matches && matches.length > 1) {
        return matches[1];
      }
    }

    return null;
  }

  useEffect(() => {
    setLoading(true);
    const checkUserColor = async () => {
      const isUserHaveColor = user?.prefs?.userPrefs?.userColor;
      if (!isUserHaveColor) {
        setUserColor();
      }
      setLoading(false);
      return;
    };
    if (user?.$id && userTeam?.$id) {
      checkUserColor();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [user, userTeam]);

  useEffect(() => {
    const getUsersAvatars = async () => {
      const usersAvatars = await storageApi.listFiles();
      const teamAvatarsArray = usersAvatars.files.map((row) => {
        return {
          userId: extractIdFromArray(row.$permissions),
          avatarId: row.$id,
        };
      });
      setTeamAvatars(teamAvatarsArray);
    };
    if (user?.$id) {
      getUsersAvatars();
    }
    // eslint-disable-next-line
  }, []);

  const setUserColor = async () => {
    const userColor = USER_COLORS[userTeam.total - 1];
    const userPrefs = {
      userColor,
      avatarId: user?.prefs?.userPrefs?.avatarId,
    };
    await api.updateUserPrefs(userPrefs);
    setStale(true);
    setLoading(false);
  };

  const handleSettingsChange = async () => {
    if (editedImage) {
      try {
        const teamLogo = await storageApi.uploadFile(
          Server.avatarsBucket,
          editedImage,
          userTeam.$id,
          user.$id
        );
        if (teamLogo) {
          if (teamLogoId) {
            await storageApi.deleteFile(Server.avatarsBucket, teamLogoId);
          }
          var newTeamLogoId = teamLogo.$id;
          setEditedImage([]);
        }
      } catch (e) {
        console.log("Error in saving image");
      }
    }

    const data = {
      allowedDays,
      pastDaysToggle,
      slots: slotsAmount,
      teamId: userTeam.$id,
      teamLogoId: newTeamLogoId || teamLogoId || "",
    };

    if (teamSettingsId) {
      // edit team settings
      try {
        await api.updateTeamSettings(
          Server.settingsCollectionID,
          teamSettingsId,
          data
        );
      } catch (e) {
        console.log("Error in editing team settings");
      }
    } else {
      // create team settings (Only first time)
      try {
        await api.createTeamSettings(
          Server.settingsCollectionID,
          data,
          userTeam.$id,
          user.$id
        );
      } catch (e) {
        console.log("Error in creating team settings");
      }
    }
  };

  const handleAvatarChange = async () => {
    try {
      const userAvatar = await storageApi.uploadFile(
        editedImage,
        userTeam.$id,
        user.$id
      );
      if (userAvatar) {
        if (user?.prefs.userPrefs.avatarId) {
          await storageApi.deleteFile(user?.prefs.userPrefs.avatarId);
        }
        const userPrefs = {
          ...user?.prefs.userPrefs,
          avatarId: userAvatar.$id,
        };
        await api.updateUserPrefs(userPrefs);
        setEditedImage([]);
      }
    } catch (e) {
      console.log("Error in saving image");
      setEditedImage([]);
    }
  };

  return (
    <div className="flex flex-row h-screen">
      {!isLoading && !isLoadingTeam && (
        <>
          <div className="flex-auto">
            <BrowserRouter>
              {user && (
                <header>
                  <TheHeader
                    user={user}
                    dispatch={dispatch}
                    startTour={setIsTourOpen}
                    userTeam={userTeam}
                  />
                </header>
              )}
              <section>
                <Switch>
                  <Route path="/dashboard">
                    {user ? (
                      <Dashboard
                        loading={loading}
                        stale={stale}
                        user={user}
                        userTeam={userTeam}
                        dispatch={dispatch}
                        useStore={useStore}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>
                  <Route path="/team">
                    {user ? (
                      <Team
                        user={user}
                        userTeam={userTeam}
                        dispatch={dispatch}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )}
                  </Route>
                  <Route path="/login">
                    {user ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Login dispatch={dispatch} setStale={setStale} />
                    )}
                  </Route>
                  <Route path="/login-from-invite">
                    {user ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <LoginFromInvite dispatch={dispatch} />
                    )}
                  </Route>
                  <Route exact path="/">
                    {user ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      <Login dispatch={dispatch} setStale={setStale} />
                    )}
                  </Route>
                  <Redirect to="/" />
                </Switch>
                <MyDialog
                  isModalOpen={isModalOpen}
                  openModal={setIsModalOpen}
                  modalContent={
                    <Settings
                      userTeam={userTeam}
                      setIsModalOpen={setIsModalOpen}
                      user={user}
                    />
                  }
                  modalTitle={"Settings"}
                  buttonText={"Apply"}
                  onSubmit={handleSettingsChange}
                  icon={<FiSettings />}
                  showButton={userTeam.$id}
                />
                <MyDialog
                  isModalOpen={isAvatarModalOpen}
                  openModal={setIsAvatarModalOpen}
                  modalContent={<ImageUploaderEditor />}
                  modalTitle={
                    user?.prefs?.userPrefs?.avatarId
                      ? "Change Profie Picture"
                      : "Set Profile Picture"
                  }
                  buttonText={"Save"}
                  onSubmit={handleAvatarChange}
                  icon={<HiOutlineUserCircle />}
                  showButton={userTeam.$id}
                />
                <Tour
                  steps={steps}
                  isOpen={isTourOpen}
                  onRequestClose={() => setIsTourOpen(false)}
                  accentColor={"#F8B401"}
                />
              </section>
            </BrowserRouter>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
