const steps = [
  {
    selector: "#teamSettings",
    content:
      "To adjust the settings to your team needs, click here. You can always come back here and update your settings.",
  },
  {
    selector: "#team",
    content:
      "To invite the team to join, click here and enter your teamates information. they will get an email from us with your invitation.",
  },
  {
    selector: "#teamates-list",
    content:
      "Evrey new team member will be added to this users menu, you can click any user to filter the calendar to see only his events.",
  },
  {
    selector: "#calendar",
    content:
      "Every team member can click on one of the allowed days (set allowed days in settings), and all team members will see it in their accounts. to remove event just click it.",
  },
];

export default steps;
