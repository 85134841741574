import { Client as Appwrite, Storage, Permission, Role } from "appwrite";
import { Server } from "../utils/config";

const avatarsBucketId = process.env.REACT_APP_AVATARS_BUCKET_ID;

let storageApi = {
  sdk: "",

  provider: () => {
    if (storageApi.sdk) {
      return storageApi.sdk;
    }
    let appwrite = new Appwrite();
    appwrite.setEndpoint(Server.endpoint).setProject(Server.project);
    const bucket = new Storage(appwrite);
    storageApi.sdk = { bucket };
    return storageApi.sdk;
  },

  getUserAvatar: (fileId) => {
    //WORKS
    return storageApi
      .provider()
      .storage.getFilePreview(avatarsBucketId, fileId);
  },
  deleteFile: (fileId) => {
    //WORKS
    return storageApi.provider().bucket.deleteFile(avatarsBucketId, fileId);
  },

  listFiles: () => {
    //WORKS
    return storageApi.provider().bucket.listFiles(avatarsBucketId);
  },

  uploadFile: (file, teamId, userId) => {
    // WORKS
    console.log("editedImage storageApi", file, userId);
    return storageApi
      .provider()
      .bucket.createFile(avatarsBucketId, "unique()", file, [
        Permission.read(Role.team(teamId)), // Only users of this team can read the document
        Permission.update(Role.user(userId)), // Only creator of document can update the document
        Permission.delete(Role.user(userId)), // Only creator of document can delete the document
      ]);
  },
};

export default storageApi;
