import create from "zustand";

export const useStore = create((set) => ({
  isModalOpen: false,
  isAvatarModalOpen: false,
  selectedMembers: {},
  allowedDays: [],
  slotsAmount: 1,
  teamSettingsId: "",
  teamOwnerId: "",
  pastDaysToggle: false,
  editedImage: [],
  teamAvatars: [],
  teamLogoId: "",
  setIsModalOpen: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
  setIsAvatarModalOpen: () =>
    set((state) => ({ isAvatarModalOpen: !state.isAvatarModalOpen })),
  setSlots: (selectedSlots) => set(() => ({ slotsAmount: selectedSlots })),
  setPastDaysToggle: (bool) => set(() => ({ pastDaysToggle: bool })),
  togglePastDaysToggle: () =>
    set((state) => ({ pastDaysToggle: !state.pastDaysToggle })),
  setSelectedMembers: (selectedMember) =>
    set(() => ({ selectedMembers: selectedMember })),
  addAllowedDay: (selectedDay) =>
    set((state) => ({ allowedDays: [...state.allowedDays, selectedDay] })),
  setAllowedDays: (allowedDays) => set(() => ({ allowedDays })),
  setTeamAvatars: (teamAvatars) => set(() => ({ teamAvatars })),
  setEditedImage: (editedImage) => set(() => ({ editedImage })),
  setTeamSettingsId: (id) => set(() => ({ teamSettingsId: id })),
  setTeamOwnerId: (id) => set(() => ({ teamOwnerId: id })),
  setTeamLogoId: (id) => set(() => ({ teamLogoId: id })),
  removeAllowedDay: (selectedDay) =>
    set((state) => ({
      allowedDays: state.allowedDays.filter((day) => day !== selectedDay),
    })),
}));
